<template>
  <div class="app-container">
    <a-spin :spinning="loading">
    <!-- <div class="menu-nav">
      <div>
        <a-icon type="setting" class="menu-nav-icon"></a-icon>
        <span class="menu-nav-text">检索进度</span>
      </div>
      <a-button-group>
        <a-button style="margin-left:10px;" type="primary" v-hasPermi="['imagesearchprogress_delete']" icon="delete" @click="handleDelete" :disabled="multiple">删除</a-button>
      </a-button-group>
    </div> -->
    <div class="table-search">
      <a-form-model :model="queryParams" ref="queryForm" size="small" layout="inline" v-show="showSearch" label-width="40px">
        <a-form-model-item label="状态" prop="status">
            <a-select v-model="queryParams.status" style="width:200px;" placeholder="请选择状态" allowClear>
              <a-select-option value="1">等待中</a-select-option>
              <a-select-option value="2">进行中</a-select-option>
              <a-select-option value="3">已完成</a-select-option>
              <a-select-option value="4">检索失败</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button icon="search" type="primary"  @click="handleQuery">查询</a-button>
          <a-button icon="a-icon-refresh" @click="resetQuery" style="margin-left: 10px">重置</a-button>
        </a-form-model-item>
        <a-form-model-item style="float: right;margin-right: 0px;">
          <a-button style="margin-left:10px;" type="primary" v-hasPermi="['imagesearchprogress_delete']" icon="delete" @click="handleDelete" :disabled="multiple">删除</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="card" style="padding:6px ;height:calc(100vh - 160px)">
    <a-table rowKey="id" :columns="columns" :dataSource="list"  :row-selection="rowSelectionConfig" 
               ref="table" :pagination="{
        defaultCurrent: queryParams.pageNum, // 默认当前页数
        defaultPageSize: queryParams.pageSize, // 默认当前页显示数据的大小
        total: total, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['20', '50', '100'],
        showTotal: (total, range) => `每页${queryParams.pageSize}条，共 ${total} 条`, // 显示总数
        onShowSizeChange: onPageSizeChange,
        // 改变每页数量时更新显示
        onChange: onPageSizeChange,
      }" bordered>
        <template v-slot:type="text">
          <span v-if="text === '0'">知识库图片</span>
          <span v-if="text === '1'">手动上传检索图片</span>
        </template>
        <template v-slot:status="text">
          <span v-if="text === '1'">等待中</span>
          <span v-if="text === '2'">进行中</span>
          <span v-if="text === '3'">已完成</span>
          <span v-if="text === '4'">检索失败</span>
        </template>
        <template v-slot:action="record">
          <a v-if="record.status === '3'" @click="toImagesearch(record)" v-hasPermi="['imagesearchprogress_search']" style="margin-right: 10px;"><a-icon type="chrome" /> 图像检索</a>
          <a href="javascript:;" v-hasPermi="['imagesearchprogress_delete']" @click="handleDelete(record)"><a-icon type="delete" /> 删除</a>
        </template>
      </a-table>
    </div>
    </a-spin>
  </div>
</template>

<script>
import {message, Modal} from 'ant-design-vue';
import { getProgressList, deleteProgress } from "@/api/knowledge/progress";

export default {
  name: "ImageSearchProgress",
  data() {
    return {
        columns: [
/*        {
          title: '文件夹名称',
          dataIndex: 'fileName',
        },*/
        {
          title: '图片名称',
          dataIndex: 'imageName',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
        },
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: {customRender: 'type'}
        },
        {
          title: '检索进度',
          dataIndex: 'progress',
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '操作',
          key: 'operation',
          width: '130px',
          align:'center',
          className: 'operation-cls',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // AI模型管理表格数据
      list: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        status: undefined
      },
      interval: null,
      totalData: {},
      selectedKeys: [],
      multiple: true,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    rowSelectionConfig() {
      return {
        selectedRowKeys: this.selectedKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedKeys = selectedRowKeys;
           this.multiple = !this.selectedKeys.length
        }
      }
    }
  },
  beforeDestroy() {
    if(this.interval) {
　　　　clearInterval(this.interval);
    }
    this.interval = null;
　},
  methods: {
    /** 查询AI模型管理列表 */
    getList() {
      this.loading = true;
      getProgressList(this.queryParams).then(res => {
        if(res.success) {
            this.list = res.rows.map(ele => {
              this.totalData[ele.id] = ele.imageName;
              return ele;
            });
            this.total = res.total;
            this.interval = setTimeout(() => {
              this.getList();
            }, 5000);
        }
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.selectedKeys = [];
      this.totalData = [];
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs["queryForm"].resetFields();
      this.handleQuery();
    },
    onPageSizeChange(current, pageSize){
      this.queryParams = {
        ...this.queryParams,
        pageNum: current,
        pageSize,
      };
      this.getList();
    },
    // 图像检索
    toImagesearch(record) {
     this.$router.push("/knowledge/imagesearch?id="+record.id+'&imageName='+record.imageName);
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids =  this.selectedKeys;
      if(row.id) {
        ids = [row.id];
      }
      const names = ids.map(ele => this.totalData[ele]);
      const that = this;
      Modal.confirm({
        title: '提示',
        content: '是否确认删除图片名称为【' + names + '】的数据项？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          deleteProgress(ids).then(res => {
            if(res.success) {
              that.selectedKeys = [];
              that.multiple = true;
              that.getList();
              message.success("删除成功");
            }
          });
        }
      });
    },
  }
};
</script>
<style scoped lang="less">
   ::v-deep .table-search .ant-form-item-label {
    width: 40px;
}
</style>
